

$( document ).ready(function() {


  
  $("#gallery-carousel").owlCarousel({
    loop:true,
    margin:10,
    center: true,
    items:3,
    lazyLoad:true,
    nav:false,
    dots:true,
    autoplay:true,
    thumbs: false,
    autoplayTimeout:6000,
    animateOut: 'fadeOut',
    navText : ['<svg viewBox="0 0 90 90"><use href="#right-arrow"></use></svg>','<svg viewBox="0 0 90 90"><use href="#right-arrow"></use></svg>'],
    responsive:{
      0:{
        items:1
      },
      768:{
        items:1
      },
      1000:{
        items:2
      }
    }
  });


 
    //slider Apartamenty
    $('.mainApartamentySlider').owlCarousel({
      items:1,
      lazyLoad:true,
      autoplayHoverPause:true,
      loop:true,
      mouseDrag : false,
      margin:10,
      autoplay:true,
      autoplayTimeout:4000,
      smartSpeed:1250,
      nav:false,
      dots:false,
			 
	    responsive:{
	        0:{
	            items:1
	        },
	        600:{
	            items:2
	        },
	        1000:{
	            items:4
	        }
	    }
    });
    //end slider Apartamenty


    //slider mainPageSlider
    $('.mainPageSlider').owlCarousel({
        items:1,
          lazyLoad:true,
		  autoplayHoverPause:true,
          loop:true,
		  mouseDrag : false,
          margin:0,
          autoplay:true,
          autoplayTimeout:4000,
		  smartSpeed:1250,
          nav:false,
          dots:false,
          navText: [$('.owl-left'),$('.owl-right')]
    });
    //end slider mainPageSlider

    //slider photoSlider
    $('.photoSlider').owlCarousel({
        items:1,
        lazyLoad:true,
        loop:true,
        margin:10,
        autoplay:true,
        autoplayTimeout:4000,
        smartSpeed:1250,
        nav:false,
        dots:true,
        //navText: [$('.owl-left'),$('.owl-right')]
  });
  //end slider photoSlider

    
      //animacja wejscia dla stron
      //document.body.classList.add('fadeIn');

    //header scroll fixed
    $(window).scroll(function(){
        if ($(window).scrollTop() >= 150) {
          $('header').addClass('header--fixed');
        }
        else {
          $('header').removeClass('header--fixed');
        }
        if (($(window).scrollTop() >= 50)) {
          if (($(window).width() > 1050)) {
            $(".reservationPanelWrap").addClass('fixed');
          }
        } else {
          if (($(window).width() > 1050)) {
            $(".reservationPanelWrap").removeClass('fixed');
          }
        }
    });
    //end header scroll fixed

    //zmiana wygladu headera gdy nie jest wlaczony slider
      if ($('.mainSlider').length === 0){
        //$('header').css('background-color','rgba(152, 142, 111, .8)');
        //$('.dropdown-menu').css('background-color','rgba(152, 142, 111, .8)');
        $('body').css('padding-top','100px');
      }
    //


    //burger menu 
    $('.burger').click(function(){
        $(this).toggleClass('active');
        $('.nav').slideToggle('fast');
    })
    //end burger menu 

    

    //pokaz formularz rozwijany konferencje
      $('.specialForm .showFormPanel img').click(function(){
        $('.formConference .formWrap').slideToggle();
      })
    //end pokaz formularz rozwijany konferencje
	
  //slider galeriabox-slider
    $('.galeriabox-slider').slick({
      infinite: true,
      speed: 900,
      lazyLoad: 'ondemand',
      centerMode: true,
      variableWidth: true,
      autoplay: true,
      autoplaySpeed: 2000,
		dots:false,
  });
    
  $('.OpinionsSlider').slick({
    infinite: true,
    speed: 900,
    slidesToShow: 3,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 3000,
		nav:true,
		dots:true,
    responsive: [
      {
          breakpoint: 1024,
          settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
          }
      },
      {
          breakpoint: 600,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1
          }
      },
      {
          breakpoint: 480,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1
          }
      }

]
    });
    
    
  $('.SpecialOfferSlider').slick({
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 3000,
		nav:true,
		dots:true,
    });




    //panelMobileBottom
    var lastScrollTop = 0;
      $(window).scroll(function(event){
        if( window.screen.width <= 768 ){
          var st = $(this).scrollTop();
          if (st > lastScrollTop){
              // downscroll code
              $('.mobileBottomPanel').slideDown()
          } else {
              // upscroll code
              //$('.mobileBottomPanel').stop().slideUp()
          }
        }else{
          $('.mobileBottomPanel').stop().slideUp()
        }
      });


     
      


      //pozycja dla kalendarza
    function topCalendar(){
      var offset = $('.reservationPanel').offset().top;
      var heightPanel = $('.reservationPanel').height();
      var calendarHeight = 271;
      var mainOffset = offset + heightPanel - 307;
      $('#calroot').css('top', mainOffset)
    }

    topCalendar()


    $(window).resize(function() {

      topCalendar()

    });

    $(window).scroll(function() {

      topCalendar()

    });
    // end pozycja dla kalendarza



});





